import { defineStore } from 'pinia'
import { ref } from 'vue'
import TableSettings from '@/types/tableSettings'
import IssueModel from '@/models/IssueModel'
import { notification } from 'ant-design-vue'
import { WrappedResponse } from 'vue-api-query'
import { openIssueStates, closedIssueStates } from '@/helpers/issueStates'
import { useRouter } from 'vue-router'

export const issueStore = defineStore('issue', () => {
  const router = useRouter()
  const loading = ref({ list: false, item: false })
  const issues = ref<WrappedResponse<IssueModel[]>|IssueModel[]>({
    data: [],
    meta: {
      per_page: 25,
      total: 0
    }
  })
  const issue = ref<WrappedResponse<IssueModel>|IssueModel|null>(null)

  function getIssues (tableSettings:TableSettings) : void {
    loading.value.list = true
    let issueModel = new IssueModel()

    // Add filters if there are any filters set.
    if (tableSettings.activeFilters) {
      Object.keys(tableSettings.activeFilters).forEach(key => {
        if (tableSettings.activeFilters[key].length) {
          issueModel = issueModel.whereIn(key, tableSettings.activeFilters[key])
        }
      })
    }

    // Check if only archived issues have to be shown.
    if (!tableSettings.activeFilters || !tableSettings.activeFilters.status || !tableSettings.activeFilters.status.length) {
      if (tableSettings.archived) {
        issueModel = issueModel.whereIn('status', closedIssueStates)
      } else {
        issueModel = issueModel.whereIn('status', openIssueStates)
      }
    }

    // Add search if available.
    if (tableSettings.search) {
      issueModel = issueModel.where('search', tableSettings.search)
    }

    // Add orderBy if sort is set.
    if (tableSettings.sort && tableSettings.sort.order && tableSettings.sort.columnKey) {
      issueModel = issueModel.orderBy(tableSettings.sort.order === 'ascend' ? tableSettings.sort.columnKey : '-' + tableSettings.sort.columnKey)
    }

    if (tableSettings.pagination) {
      issueModel = issueModel.limit(tableSettings.pagination.pageSize).page(tableSettings.pagination.current)
    }

    issueModel.get().then((r) => {
      issues.value = r
    }).catch(() => {
      notification.error({ message: 'Fout tijdens het ophalen van de servicemeldingen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
    }).finally(() => {
      loading.value.list = false
    })
  }

  function updateIssue (model:IssueModel) : void {
    loading.value.item = true

    model.save().then((r:WrappedResponse<IssueModel>|IssueModel) => {
      issue.value = r
      notification.success({ message: 'Servicemelding opgeslagen!', description: 'De servicemelding is succesvol opgeslagen.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Fout tijdens het opslaan van de servicemelding!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
    }).finally(() => {
      loading.value.item = false
    })
  }

  function getIssue (id:string) : void {
    loading.value.item = true
    const issueModel = new IssueModel()

    issueModel.find(id).then((r:WrappedResponse<IssueModel>|IssueModel) => {
      issue.value = r
    }).catch((e:{ response: { status:number } }) => {
      if (e.response.status === 403) {
        notification.warning({
          message: 'U heeft niet de juiste rechten!',
          description: 'U heeft niet de juiste rechten om deze servicemelding te bekijken.',
          duration: 5
        })
      } else {
        notification.error({
          message: 'Fout tijdens het ophalen van deze servicemelding!',
          description: 'Er is iets mis gegaan. Probeer het later nog een keer.'
        })
      }
      router.push({ name: 'Issues' })
    }).finally(() => {
      loading.value.item = false
    })
  }

  return { issues, issue, loading, getIssues, getIssue, updateIssue }
})
